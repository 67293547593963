import React, { Component } from "react"
import { RedButton } from "../components/button-red"

export class OptionsForm extends Component {
    render() {
        return (
            <div className="border bg-gradient-to-r from-dhredfrom to-dhredto rounded-xl sm:w-96 mx-auto md:w-96 h-fit shadow-2xl mt-10">
                <p className="font-bold px-5 py-5 text-xl text-white text-center">Options Of Selling Your Home</p>
                <hr className="border" />
                <div className="bg-white px-6 rounded-b-xl pt-5 pb-2">
                    <p className="text-center max-w-sm mx-auto">See which option is right for you to maximize your cash return and minimize the hassle</p>
                    <div className="flex justify-center">
                        <RedButton buttonText="See Options" buttonPage="/compare" />
                    </div>
                </div>
            </div>
        );
    }
}

