import React, { useState, useRef } from 'react'
import { LoadScript, Autocomplete } from '@react-google-maps/api'
import Lock from "../icons/lock"
import { Link } from "gatsby"

const libraries = ['places'];
const apiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY

const GetOfferForm = (props) => {
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const autocompleteRef = useRef(null);

    const handlePlaceChanged = () => {
        if (autocompleteRef.current !== null) {
            const place = autocompleteRef.current.getPlace();
            if (place && place.formatted_address) {
                setAddress(place.formatted_address);
                setError('');
            } else {
                setError('No details available for the input: ' + place.name);
            }
        } else {
            setError('Autocomplete is not loaded yet.');
        }
    }

    return (
        <form name={props.formName} method="POST" data-netlify="true" className="py-5 lg:py-3 xl:py-0">
            <input type="hidden" name="form-name" value={props.formName} />
            <input type="hidden" name="address" value={address} />
            <div className="border bg-gradient-to-r from-dhgreenfrom to-dhgreento rounded-xl sm:w-96 mx-auto md:w-96 h-fit shadow-2xl">
                <p className="font-bold px-5 py-3 text-xl text-white text-center max-w-sm mx-auto">Get A Cash Offer Today, Sell Your Home In A Matter Of Days!</p>
                <hr className="border" />
                <div className="bg-white px-5 rounded-b-xl">
                    <p className="text-center py-5 max-w-sm mx-auto">Any Home, Any Condition, Fair Cash Offers</p>
                    <p className="text-center max-w-sm mx-auto">Get a FREE, no obligation cash estimate for your home within a matter of minutes by filling out the following form!</p>
                    <div className="flex justify-center">
                        <div className="px-5 py-7 bg-white bg-opacity-30 rounded-xl">
                            <div className="flex flex-wrap -mx-2 mb-6">
                                <div className="w-full md:mb-0 px-1">
                                    <label className="block tracking-wide text-xs mb-2 text-left" htmlFor="address">Property Address:
                                        <LoadScript
                                            googleMapsApiKey={apiKey}
                                            libraries={libraries}
                                            onLoad={() => console.log('Google Maps script loaded successfully')}
                                            onError={(e) => setError('Error loading Google Maps script: ' + e.message)}
                                        >

                                            <Autocomplete
                                                onLoad={(autocomplete) => {
                                                    autocompleteRef.current = autocomplete;
                                                    autocomplete.setFields(['formatted_address']);
                                                }}
                                                onPlaceChanged={handlePlaceChanged}
                                            >
                                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-4 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="address" type="text" name="address" value={address} onChange={(e) => setAddress(e.target.value)} required placeholder="1234 Example Way, Sacramento CA 12345" />
                                            </Autocomplete>
                                            {error && <p style={{ color: 'red' }}>{error}</p>}
                                        </LoadScript>
                                    </label>
                                </div>
                                <div className="w-full px-1">
                                    <label className="block tracking-wide text-xs text-left" htmlFor="phone">Phone Number:
                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-4 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" name="phone" type="tel" required placeholder="EX: 916-245-0073" />
                                    </label>
                                </div>
                            </div>
                            <button type="submit" className="mt-6 mb-1 relative h-10 py-2 px-3 mx-auto flex justify-center transition-colors rounded-lg focus:shadow-outline bg-gradient-to-r from-dhgreenfrom to-dhgreento text-white hover:shadow-lg hover:shadow-dhgreen font-semibold">
                                <span className="text-xs pt-1 sm:pt-0 sm:text-base">Get My FREE Home Offer!</span>
                            </button>
                            <div className="pt-5 flex justify-center">
                                <Link className="flex space-x-1 hover:opacity-70" to="/privacy-policy">
                                    <Lock />
                                    <span className="text-gray-400 text-xs mt-[0.1rem]">We Value Your Privacy</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default GetOfferForm

