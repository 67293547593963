import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FaqBody } from "../components/faq-body"

const FaqPage = (props) => {
    return (
        <Layout>
            <Seo
                title="FAQ - Dahlia Homes"
                description=""
                keywords={[]}
                location={props.location.pathname}
            />
            <div>
                <FaqBody />
            </div>
        </Layout>
    )
}
export default FaqPage