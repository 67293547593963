import React, { Component } from "react"
import { Link } from "gatsby"
import GetOfferForm from "./get-offer-form"
import { OptionsForm } from "./options-form"
import { DownloadGuide } from "./download-guide"
import { GreenButton } from "../components/button-green"

export class FaqBody extends Component {
    render() {
        return (
            <div className="bg-dhgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <h2 className="font-bold text-2xl">Frequently Asked Questions</h2>
                            <p className="pt-2">Maybe you have a few questions. That’s okay; most people do. So here’s a quick collection of some of the questions people ask us… along with our answers.
                                If you still have a question, don’t hesitate to contact us (or give us a call) and we’ll be happy to answer it for you.
                            </p>

                            <p className="pt-2 font-bold">Q:  Will you be listing my house on the MLS or actually buying it?</p>

                            <p className="pt-2"><b>A:</b>  Great question. We’re not agents, and we don’t list houses. We are professional home buyers: We buy houses Sacramento that meet our purchasing criteria. From there we may repair the house and resell
                                it to another homeowner or keep it as a rental ourselves.
                            </p>

                            <p className="pt-2 font-bold">Q:  Do you pay fair prices for properties?</p>

                            <p className="pt-2"><b>A:</b>  Many of the houses we purchase are below market value (we do this so we can resell it at a profit to another homeowner). We are looking to get a fair discount on a property. However, in our experience,
                                many sellers aren’t necessarily expecting a large “windfall” on the property but rather appreciate that we can offer cash, we close very quickly (no waiting for financing), and no time or effort or expense is required on your
                                part of fix up the property or pay agent fees. If that’s what you’re looking for and you see the value in getting your house sold fast… let’s see if we can come to a fair win-win price. (Besides, our no-obligation pricing commitment
                                means that you do not have to move forward with the offer we give… but it’s good to know what we’re offering!)
                            </p>

                            <p className="pt-4"><i><Link to="/home-sellers-guide" className="text-dhred hover:opacity-70">DOWNLOAD our free guide that walks you through the Pros and Cons </Link>(plus the cost and timeline) of selling your house to a real estate investor… plus learn the pros, cons, and costs of the other two alternatives…
                                listing with an agent or selling it yourself.</i>
                            </p>

                            <p className="py-3 text-dhred"><Link to="/home-sellers-guide" className="hover:opacity-70">Head on over here download your free guide →</Link></p>

                            <p className="pt-2 font-bold">Q:  How do you determine the price to offer on my house?</p>

                            <p className="pt-2"><b>A:</b>  Great question, and we’re an open book: Our process is very straightforward. We look at the location of the property, what repairs are needed, the current condition of the property, and values of
                                comparable houses sold in the area recently. As you know, house values have taken a huge hit in the last 5 years and most areas still haven’t seen prices come back up. We take many pieces of information into consideration… and come
                                up with a fair price that works for us and works for you too.
                            </p>

                            <p className="pt-2 font-bold">Q:  Are there any fees or commissions to work with you?</p>

                            <p className="pt-2"><b>A:</b>  This is what makes us stand out from the traditional method of selling your house: There are NO fees or commissions when you sell your house to us. We’ll make you an offer,
                                and if it’s a fit then we’ll buy your house (and we’ll often pay for the closing costs too!). No hassle. No fees. We make our money after we pay for repairs on the house (if any) and sell it for a profit
                                (we’re taking the risks here on whether we can sell it for a profit or not, once we buy the house from you… the responsibility is ours and you walk away without the burden of the property and its payments… and often with cash in your hand).
                            </p>

                            <p className="pt-2 font-bold">Q:  How are you different from a real estate agent?</p>

                            <p className="pt-2"><b>A:</b>   Real estate agents list properties and hope that someone will buy them. The agent shows the properties to prospective buyers if there are any (the average time to sell a property in many markets
                                right now is 6-12 months) and then take a percentage of the sale price if they find a buyer. Oftentimes, the agent’s commission is 3-6% of the sale price of your house (so if it’s a $100,000 house, you’ll pay between $3,000 – $6,000 in
                                commissions to an agent). Agents provide a great service for those that can wait 6-12 months to sell and who don’t mind giving up some of that sale price to pay for the commissions. But that’s where we’re different: We’re not agents, we’re
                                home buyers. Our company actually buys houses. We don’t list houses. Since we’re actually the one buying the house from you, and we pay with all cash… we can make a decision to buy your house within a couple of days (sometimes the same day).
                                Again, we make our living by taking the risk to buy the house with our own cash, repair the house, and market it ourselves to find a buyer (which is the hard part in this market).
                            </p>

                            <p className="pt-2 font-bold">Q:  Is there any obligation when I submit my info?</p>

                            <p className="pt-2 pb-4"><b>A:</b>  There is absolutely zero obligation for you. Once you tell us a bit about your property, we’ll take a look at things, maybe set up a call with you to find out a bit more, and make you an all-cash offer that’s
                                fair for you and fair for us. From there, it’s 100% your decision on whether or not you’d like to sell your house to us… and we won’t hassle you, won’t harass you… it’s 100% your decision and we’ll let you decide what’s right for you.
                            </p>

                            <div className="flex justify-center">
                                <GreenButton buttonText="Get My FREE Cash Offer!" buttonPage="/get-offer" />
                            </div>
                        </div>

                        <div className="mx-auto py-4">
                            <div className="flex justify-center">
                                <GetOfferForm formName="FAQ Page" />
                            </div>

                            <div className="flex justify-center">
                                <OptionsForm />
                            </div>

                            <div className="flex justify-center">
                                <DownloadGuide />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}