import React, { Component } from "react"
import { RedButton } from "../components/button-red"
import { StaticImage } from "gatsby-plugin-image"

export class DownloadGuide extends Component {
    render() {
        return (
            <div className="bg-white rounded-xl w-auto sm:max-w-sm mx-auto h-fit shadow-2xl mt-10">
                <p className="font-bold px-5 py-5 text-xl text-center">Download Our FREE Guide</p>
                <div className="bg-white px-5 rounded-b-xl">
                    <p className="text-center max-w-sm mx-auto">Learn The Pros and Cons Of Selling Your House To A Local Professional Home Buyer– FREE Guide: </p>

                    <div className="flex justify-center pt-6 pb-3">
                        <StaticImage src="../images/free-guide.jpeg" className="w-1/4 md:w-1/2 border" loading="eager" placeholder="none" title="Dahlia Homes" alt="Dahlia Homes" />
                    </div>
                    <div className="flex justify-center pb-2">
                        <RedButton buttonText="Download" buttonPage="/home-sellers-guide" />
                    </div>
                </div>
            </div>
        );
    }
}

